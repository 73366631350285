import { Module } from 'vuex'
import { ILoginState } from './types'
import { IRootState } from '../types'
import { accountLoginRequest } from '@/servise/login/login'
import router from '@/router'
import { mapManustoRoutes, mapMenusToPermissions } from '@/utils/map-menus'
import localCache from '@/utils/cache'

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: '',
      userInfo: {},
      userMenus: [],
      permissions: []
    }
  },
  mutations: {
    changeToken(state, token: string) {
      state.token = token
    },
    changeUserInfo(state, userInfo: any) {
      state.userInfo = userInfo
    },
    changeUserMenus(state, userMenus: any) {
      state.userMenus = userMenus
      const routes = mapManustoRoutes(userMenus)
      // console.log(routes)

      //将routes=>routes.main.children，动态添加路由
      routes.forEach((route) => {
        router.addRoute('main', route)
      })

      //获取用户按钮权限
      const permissions = mapMenusToPermissions(userMenus)

      state.permissions = permissions
    }
  },
  actions: {
    async accountLoginAction({ commit, dispatch }, payload: any) {
      //1、实现登录逻辑
      const loginResult = await accountLoginRequest(payload)
      console.log(loginResult)

      const { token } = loginResult.data
      console.log(token)

      commit('changeToken', token)
      localCache.setCache('token', token)

      //发起初始化请求（类别、相机灯光的options数据）,{ root: true }调用跟组件下的数据
      dispatch('getInitiaDataAction', null, { root: true })

      //2、请求用户信息
      const userInfo = loginResult.data.user
      commit('changeUserInfo', userInfo)
      localCache.setCache('userInfo', userInfo)

      // //3、请求用户菜单
      const userMenus = loginResult.data.menuData
      commit('changeUserMenus', userMenus)
      localCache.setCache('userMenus', userMenus)

      //4、跳转到首页
      router.push('/main')
    },
    //刷新（本地加载）
    loadLocalLogin({ commit, dispatch }) {
      const token = localCache.getCache('token')
      if (token) {
        commit('changeToken', token)
        dispatch('getInitiaDataAction', null, { root: true })
      }
      const userInfo = localCache.getCache('userInfo')
      if (userInfo) {
        commit('changeUserInfo', userInfo)
      }
      const userMenus = localCache.getCache('userMenus')
      if (userMenus) {
        commit('changeUserMenus', userMenus)
      }
    }
  },
  getters: {}
}

export default loginModule
