import { Module } from 'vuex'
import { IPanelState } from './types'
import { IRootState } from '../../types'
import {
  getMonthlyDebugCounts,
  getMonthlyCaseCounts,
  getMonthlyCategory
} from '@/servise/main/panel/panel'
// import LocalCache from '@/utils/cache'

const scheduleModule: Module<IPanelState, IRootState> = {
  namespaced: true,
  state() {
    return {
      debugList: [],
      caseList: [],
      caseCount: 0,
      debugCount: 0,
      categoryList: []
    }
  },
  mutations: {
    changeDebugList(state, list: any[]) {
      state.debugList = list
    },
    changeDebugCount(state, count: number) {
      state.debugCount = count
    },
    changeCaseList(state, list: any[]) {
      state.caseList = list
    },
    changeCaseCount(state, count: number) {
      state.caseCount = count
    },
    changeCategoryList(state, list: any[]) {
      state.categoryList = list
    }
  },
  getters: {
    pageListData(state) {
      return (pageName: string) => {
        return (state as any)[pageName + 'List']
      }
    },
    pageListCount(state) {
      return (pageName: string) => {
        return (state as any)[pageName + 'Count']
      }
    }
  },
  actions: {
    //获取数据
    // async getPageListAction({ commit }, payload: any) {
    //   //1、获取pageUrl
    //   const pageUrl = '/reservation/findReservationCode'

    //   //2、对页面发送网络请求
    //   const pageResult = await getReservationCode(pageUrl, payload.queryInfo)
    //   console.log(pageResult)

    //   //3、将请求结果赋值给state
    //   const { list, totalCount } = pageResult.data

    //   // const changePageName =
    //   //   pageName.slice(0, 1).toUpperCase() + pageName.slice(1)

    //   commit(`changeCodeList`, list)
    //   commit(`changeCodeCount`, totalCount)
    // },
    //获取工程师调试数据
    async getMonthlyDebugAction({ commit }, payload: any) {
      // console.log(payload)

      //1、编辑数据请求
      const { month } = payload

      const pageResult = await getMonthlyDebugCounts(
        '/panel/getMonthlyDebugCounts',
        {
          month
        }
      )
      console.log(pageResult)
      commit(`changeDebugList`, pageResult.data.engineers)
      commit(`changeDebugCount`, pageResult.data.AppointmentCount)
    },
    //获取工程师案例数据
    async getMonthlyCaseAction({ commit }, payload: any) {
      console.log(payload)

      //1、编辑数据请求
      const { month } = payload

      const pageResult = await getMonthlyCaseCounts(
        '/panel/getMonthlyCaseCounts',
        {
          month
        }
      )
      console.log(pageResult)
      commit(`changeCaseList`, pageResult.data.engineers)
      commit(`changeCaseCount`, pageResult.data.totalImgCount)
    },
    //获取工程师案例数据
    async getMonthlyCategoryAction({ commit }, payload: any) {
      console.log(payload)

      //1、编辑数据请求
      const { month } = payload

      const pageResult = await getMonthlyCategory('/panel/getMonthlyCategory', {
        month
      })
      console.log(pageResult)
      commit(`changeCategoryList`, pageResult.data)
      // commit(`changeCaseCount`, pageResult.data.totalImgCount)
    }
  }
}

export default scheduleModule
