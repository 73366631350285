import myRequest from '@/servise'
import { IDataType } from '@/servise/types'

export function getReservationCode(url: string, queryInfo: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}

//生成预约码
export function generateReservationCode(url: string, queryInfo: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}
//修改预约码为已使用
export function updateReservationCode(url: string, queryInfo: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}
//更改工程师
export function replaceEngineer(url: string, queryInfo: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}

//修改预约状态
export function updateAppointmentStatus(url: string, queryInfo: any) {
  return myRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}
