import { Module } from 'vuex'
import { IScheduleState } from './types'
import { IRootState } from '../../types'
import {
  getEngineerSchedules,
  getAppointmentStatus,
  batchSchedule,
  dailySchedule
} from '@/servise/main/schedules/schedules'
// import LocalCache from '@/utils/cache'

const scheduleModule: Module<IScheduleState, IRootState> = {
  namespaced: true,
  state() {
    return {
      processList: [],
      processCount: 0,
      scheduleList: [],
      intervalList: []
    }
  },
  mutations: {
    changeProcessList(state, list: any[]) {
      state.processList = list
    },
    changeProcessCount(state, count: number) {
      state.processCount = count
    },
    changeScheduleList(state, list: any[]) {
      state.scheduleList = list
    },
    changeIntervalList(state, list: any[]) {
      state.intervalList = list
    }
  },
  getters: {
    pageListData(state) {
      return (pageName: string) => {
        return (state as any)[pageName + 'List']
      }
    },
    pageListCount(state) {
      return (pageName: string) => {
        return (state as any)[pageName + 'Count']
      }
    }
  },
  actions: {
    //获取数据
    async getPageListAction({ commit }, payload: any) {
      //1、获取pageUrl
      const pageUrl = '/engineer/getEngineerSchedules'

      //2、对页面发送网络请求
      const pageResult = await getEngineerSchedules(pageUrl, payload)
      console.log(pageResult)

      commit(`changeScheduleList`, pageResult)
    },
    //查找工程师排班详情
    async findAppointmentStatus({ commit }, payload: any) {
      console.log(payload)

      //1、编辑数据请求
      const { engineerId } = payload

      const pageResult = await getAppointmentStatus(
        '/engineer/getAppointmentStatus',
        {
          engineerId
        }
      )
      console.log(pageResult)
      commit(`changeIntervalList`, pageResult.data)
    },
    async getBatchSchedule({ dispatch }, payload: any) {
      console.log(payload)

      const { engineers, shift } = payload.params
      const startTime = payload.params.createTime[0]
      const endTime = payload.params.createTime[1]

      // 将日期转换为 ISO 格式字符串，并确保时间部分为午夜
      const startTimeISO = new Date(
        startTime.getFullYear(),
        startTime.getMonth(),
        startTime.getDate()
      ).toISOString()
      const endTimeISO = new Date(
        endTime.getFullYear(),
        endTime.getMonth(),
        endTime.getDate()
      ).toISOString()

      await batchSchedule('/engineer/batchSchedule', {
        engineers,
        shift,
        startTime: startTimeISO,
        endTime: endTimeISO
      })

      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1

      //2、请求最新数据
      dispatch('getPageListAction', {
        year: currentYear,
        month: currentMonth
      })
    },
    //查找工程师排班详情
    async editDailySchedule({ dispatch }, payload: any) {
      console.log(payload)

      //1、编辑数据请求
      const { day, shiftId, engineerId, isEdit } = payload

      const pageResult = await dailySchedule('/engineer/dailySchedule', {
        engineerId,
        shiftId,
        day,
        isEdit
      })
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1
      console.log(pageResult)
      //2、请求最新数据
      dispatch('getPageListAction', {
        year: currentYear,
        month: currentMonth
      })
    }
  }
}

export default scheduleModule
