import { Module } from 'vuex'
import { IReservationState } from './types'
import { IRootState } from '../../types'
import {
  generateReservationCode,
  updateReservationCode
} from '@/servise/main/reservation/reservation'
// import LocalCache from '@/utils/cache'

const scheduleModule: Module<IReservationState, IRootState> = {
  namespaced: true,
  state() {
    return {
      codeList: [],
      codeCount: 0
    }
  },
  mutations: {
    changeCodeList(state, list: any[]) {
      state.codeList = list
    },
    changeCodeCount(state, count: number) {
      state.codeCount = count
    }
  },
  getters: {
    pageListData(state) {
      return (pageName: string) => {
        return (state as any)[pageName + 'List']
      }
    },
    pageListCount(state) {
      return (pageName: string) => {
        return (state as any)[pageName + 'Count']
      }
    }
  },
  actions: {
    //生成预约码
    async generateCodeAction({ dispatch }, payload: any) {
      //1、编辑数据请求
      const { pageName, parsedNum, parsedMonth } = payload

      const pageResult = await generateReservationCode(
        '/reservation/generateReservationCode',
        {
          num: parsedNum,
          month: parsedMonth
        }
      )
      console.log(pageResult)
      // commit(`changeIntervalList`, pageResult.data)
      // 2、请求最新数据
      dispatch(
        'home/getPageListAction',
        {
          pageName,
          queryInfo: {
            page: 0,
            size: 10
          }
        },
        { root: true }
      )
    },
    //修改预约码为已使用
    async updateReservationCode({ dispatch }, payload: any) {
      // console.log(payload)

      //1、编辑数据请求
      const { codeId, pageName } = payload

      const pageResult = await updateReservationCode(
        '/reservation/updateReservationCode',
        {
          codeId
        }
      )
      console.log(pageResult)
      // commit(`changeIntervalList`, pageResult.data)
      // 2、请求最新数据
      dispatch(
        'home/getPageListAction',
        {
          pageName,
          queryInfo: {
            page: 0,
            size: 10
          }
        },
        { root: true }
      )
    }
  }
}

export default scheduleModule
